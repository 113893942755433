import * as THREE from 'three'
import { useState, useRef } from 'react'
import { useLayoutEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Center, AccumulativeShadows, RandomizedLight, OrbitControls, Environment, useGLTF } from '@react-three/drei'
import { FlakesTexture } from 'three-stdlib'

export default function App() {
  const [cameraPosition, setCameraPosition] = useState({ x: -9.15, y: 9.51, z: 7.50 });
  
  // OrbitControls Change Event Handler
  const handleOrbitControlsChange = (event) => {
    const { object } = event.target;
    setCameraPosition({
      x: object.position.x.toFixed(2),
      y: object.position.y.toFixed(2),
      z: object.position.z.toFixed(2)
    });
  };

  // Function to handle copying coordinates to clipboard
  const handleCopyCoordinates = () => {
    const coordinates = `{ x: ${cameraPosition.x}, y: ${cameraPosition.y}, z: ${cameraPosition.z} }`;
    navigator.clipboard.writeText(coordinates).then(() => {
      alert(`Copied to clipboard: ${coordinates}`);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
    <Canvas shadows camera={{ position: [-9.15, 9.51, 7.50 ], fov: 35 }}>
      <group position={[0, -1, 0]}>
        <Center top>
          <Suzi rotation={[-0.63, 0, 0]} scale={.5} />
        </Center>
        <Center top position={[-2, 0, 1]}>
          <mesh castShadow>
            <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="lightblue" />
          </mesh>
        </Center>
        <Center top position={[2.5, 0, 1]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
            <boxGeometry args={[0.5, 0.5, 0.5]} />
            <meshStandardMaterial color="indianred" />
          </mesh>
        </Center>
        <AccumulativeShadows temporal frames={100} color="orange" colorBlend={2} toneMapped={true} alphaTest={0.75} opacity={2} scale={12}>
          <RandomizedLight intensity={Math.PI} amount={8} radius={4} ambient={0.5} position={[5, 5, -10]} bias={0.001} />
        </AccumulativeShadows>
      </group>
      <OrbitControls
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2} 
        onChange={handleOrbitControlsChange}
      />
      <Environment preset="city" />
    </Canvas>
    {/* <!-- Camera Coordinates Box --> */}
    <div
      style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '10px', borderRadius: '5px' }}
      onClick={handleCopyCoordinates}
    >
      <p>Camera Position:</p>
      <p>X: {cameraPosition.x}</p>
      <p>Y: {cameraPosition.y}</p>
      <p>Z: {cameraPosition.z}</p>
    </div>
  </div>
  )
}

function printStats() {
  const statsEl = (
    <h1>
      These are some stats.
    </h1>
  )
}

function Suzi(props) {
  // const { scene, materials } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/suzanne-high-poly/model.gltf')
  // const { scene, materials } = useGLTF('./assets/monkey.gltf')
  // const { scene, materials } = useGLTF('./assets/mfemoji.gltf')
  // const { scene, materials } = useGLTF('/assets/mf-gltf/Middle_Finger_Emoji.gltf')
  const { scene, materials } = useGLTF('/assets/mf-gltf/Middle_Finger_Emoji-Basic.gltf')


  const suziRef = useRef();
  
  useLayoutEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
    materials.default.color.set('orange')
    materials.default.roughness = 0
    materials.default.normalMap = new THREE.CanvasTexture(new FlakesTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping)
    materials.default.normalMap.repeat.set(40, 40)
    materials.default.normalScale.set(0.1, 0.1)

  });

  useFrame((state, delta) => {
    if (suziRef.current) {
      suziRef.current.rotation.y += (Math.PI * 2) / (60 * 60 * 0.325); // Full rotation in 60 seconds
    }
  });
  return <primitive ref={suziRef} object={scene} {...props} />
}
